import { axios, API_ROUTES } from "@/config/index.js";

export default {
  async get(processlawyerId) {
    let result = await axios.get(
      `${API_ROUTES.traceprocesslawyers.get}/${processlawyerId}`
    );

    return result;
  },

  async save(data) {
    let result = await axios.post(
      `${API_ROUTES.traceprocesslawyers.save}`,
      data
    );

    return result;
  },

  async delete(id) {
    let result = await axios.delete(
      `${API_ROUTES.traceprocesslawyers.delete}/${id}`
    );

    return result;
  },

  async update(id, data) {
    let result = await axios.patch(
      `${API_ROUTES.traceprocesslawyers.update}/${id}`,
      data
    );

    return result;
  },

  async getDocuments(traceProcessLawyertId) {
    let result = await axios.get(
      `${API_ROUTES.traceprocesslawyersDocuments.get}/${traceProcessLawyertId}`
    );

    return result;
  },

  async uploadDocument(data) {
    let result = await axios.post(
      `${API_ROUTES.traceprocesslawyersDocuments.save}`,
      data
    );

    return result;
  },

  async deleteDocument(id) {
    let result = await axios.delete(
      `${API_ROUTES.traceprocesslawyersDocuments.delete}/${id}`
    );

    return result;
  },

  async getNotes(traceProcessId) {
    let result = await axios.get(
      `${API_ROUTES.traceprocesslawyersNotes.get}/${traceProcessId}`
    );

    return result;
  },

  async saveNote(data) {
    let result = await axios.post(
      `${API_ROUTES.traceprocesslawyersNotes.save}`,
      data
    );

    return result;
  },

  async find(processLawyertId) {
    let result = await axios.get(
      `${API_ROUTES.processlawyerOne.get}/${processLawyertId}`
    );

    return result;
  }
};
